import React from "react"
import NavbarLower from "../components/App/NavbarLower"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'

const Home = ({ data }) => {
  return (
    <Layout>
      <NavbarLower />
      <PageBanner
                pageTitle="Contact" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="お問い合わせ完了" 
            />      
      <div className="container">
      <div className="jumbotron text-center">
  <h1 className="display-3">Thank You!</h1>
  <p className="lead">お問い合わせいただきありがとうございます。<br />24時間以内に担当からご連絡いたします。</p>
  <hr />

</div>
</div>
      <Footer />
    </Layout>
  )
}

export default Home